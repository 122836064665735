import { HoverCardTrigger } from '@radix-ui/react-hover-card';
import { cn } from '~/utils/common';
import type { LithostratAgePartsFragment } from '~/apollo/generated/schema';
import { HoverCard, HoverCardContent } from '~/components/common/HoverCard';
import { mostSpecificAge } from '~/utils/modules/lithostratAge';

type Age = Pick<
  LithostratAgePartsFragment,
  'era' | 'system' | 'series' | 'stage'
>;

function hasAnyAgeValues(age: Age) {
  return [age.era, age.system, age.series, age.stage].reduce(
    (acc, cur) => acc || (!!cur && cur.length > 0),
    false,
  );
}

type AgeTableProps = {
  age: Age;
};

export function AgeTable({ age }: AgeTableProps) {
  const renderRow = (
    label: string,
    value: string | null | undefined,
  ): JSX.Element | null => {
    if (!value) return null;
    return (
      <tr>
        <td style={{ paddingRight: '5px' }}>
          <span className="text-muted" style={{ fontSize: '9pt' }}>
            {label}
          </span>
        </td>
        <td style={{ padding: '2px' }}>{value}</td>
      </tr>
    );
  };

  return (
    <table className="w-full">
      <tbody>
        {renderRow('Era', age.era)}
        {renderRow('System', age.system)}
        {renderRow('Series', age.series)}
        {renderRow('Stage', age.stage)}
      </tbody>
    </table>
  );
}

type Props = {
  startAge?: Omit<LithostratAgePartsFragment, 'id'> | null;
  endAge?: Omit<LithostratAgePartsFragment, 'id'> | null;
  renderAgeText?: (text: string) => JSX.Element;
};

export function FullAgePopover({ startAge, endAge, renderAgeText }: Props) {
  if (!startAge && !endAge) return null;

  const startAgeText = mostSpecificAge(startAge);
  const endAgeText = mostSpecificAge(endAge);

  let basinAgeText: string = '';
  if (startAgeText) {
    basinAgeText = startAgeText;
    if (endAgeText && startAgeText !== endAgeText) {
      basinAgeText += ` - ${endAgeText}`;
    }
  } else if (!startAge && endAgeText) {
    basinAgeText = endAgeText;
  }

  const hasStartAgeValues = startAge && hasAnyAgeValues(startAge);
  const hasEndAgeValues = endAge && hasAnyAgeValues(endAge);
  const hasBothAges = hasStartAgeValues && hasEndAgeValues;

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <span className="cursor-help border-b border-dotted border-b-slate-400">
          {renderAgeText ? (
            renderAgeText(basinAgeText)
          ) : (
            <span>{basinAgeText}</span>
          )}
        </span>
      </HoverCardTrigger>
      <HoverCardContent>
        <div className="max-w-full p-2">
          <div
            className={cn('grid gap-6', {
              'lg:grid-cols-2': hasBothAges,
            })}
          >
            {hasStartAgeValues && (
              <div>
                {hasBothAges && (
                  <h5 style={{ margin: '0 0 5px 0' }}>Start Age</h5>
                )}
                <AgeTable age={startAge} />
              </div>
            )}
            {hasEndAgeValues && (
              <div>
                {hasBothAges && (
                  <h5 style={{ margin: '0 0 5px 0' }}>End Age</h5>
                )}
                <AgeTable age={endAge} />
              </div>
            )}
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}
