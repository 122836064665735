import type { PureQueryOptions } from '@apollo/client';
import { useQuery , gql } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';

import * as fragments from '~/apollo/fragments';
import type {
  CompanyAdminPageQuery,
  CompanyAdminPageQueryVariables,
} from '~/apollo/generated/schema';
import { Role } from '~/apollo/generated/schema';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { LocalDate } from '~/components/common/LocalDate';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CompanyAdminUserList } from '~/components/company/CompanyAdminUserList';
import { CreateUserModal } from '~/components/company/CreateUserModal';
import { UpdateCompanyPanel } from '~/components/company/UpdateCompanyPanel';
import { useAuth } from '~/contexts/auth';

const COMPANY_ADMIN_PAGE = gql`
  query CompanyAdminPage($id: Int!) {
    companyList(id: $id) {
      ...companyParts
      user {
        ...userParts
      }
    }
  }

  ${fragments.companyParts}
  ${fragments.userParts}
`;

export default function CompanyAdminPage() {
  const { authority } = useAuth();
  if (!authority) throw new Error('Auth required');
  const companyId = authority.user.companyId;

  const { data, loading } = useQuery<
    CompanyAdminPageQuery,
    CompanyAdminPageQueryVariables
  >(COMPANY_ADMIN_PAGE, {
    variables: { id: companyId },
  });

  const refetchQueries: [PureQueryOptions<CompanyAdminPageQueryVariables>] = [
    {
      query: COMPANY_ADMIN_PAGE,
      variables: { id: companyId },
    },
  ];

  const company = data?.companyList.find(c => c.id === companyId);

  if (loading) return <SpinnerPlaceholder />;
  if (!company) throw new Error('Error loading company.');

  const activeUsers = company.user.filter(user => !user.accountLocked);

  return (
    <>
      <UpdateCompanyPanel company={company} />

      <Panel className="mt-6">
        <Panel.Heading>
          <div className="float-right">
            <CreateUserModal company={company} refetchQueries={refetchQueries}>
              {toggleCreateUserModal => (
                <Button
                  color="primary"
                  size="xs"
                  onClick={toggleCreateUserModal}
                  className="gap-2"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  Create User
                </Button>
              )}
            </CreateUserModal>
          </div>
          <Panel.Title>
            <strong>Users</strong> {activeUsers.length} active users
            <RoleSecured roles={[Role.RoleAdmin]}>
              <div className="text-slate-600 text-sm">
                {company.lastActivity && (
                  <>
                    Last Activity <LocalDate date={company.lastActivity} />
                  </>
                )}
                {company.activityCount && (
                  <>
                    {' - '}
                    Activity Count {company.activityCount}
                  </>
                )}
              </div>
            </RoleSecured>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Body>
          <CompanyAdminUserList users={company.user} />
        </Panel.Body>
      </Panel>
    </>
  );
}
