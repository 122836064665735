import type { FilterSearchProps } from '~/components/common/FilterSearch';
import { FilterSearch } from '~/components/common/FilterSearch';
import type { SortIndicatorProps } from '~/components/common/icons/SortIndicator';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import { StudyListItem } from '~/components/upload/study/StudyListItem';
import type { SortableStudy } from '~/routes/upload/model/__uploadModelsPage/studies';

type Props = {
  studies: SortableStudy[];
  sortIndicatorProps: SortIndicatorProps;
  filterSearchProps: FilterSearchProps;
};

export function StudyList({
  studies,
  sortIndicatorProps: siProps,
  filterSearchProps,
}: Props) {
  return (
    <div>
      <FilterSearch {...filterSearchProps} />

      <table className="table table-compact w-full  border border-blue-100">
        <thead>
          <tr>
            <th className="text-center lg:col-span-1/12">
              <SortTrigger
                colName="approvalState"
                sortIndicatorProps={siProps}
                filterable
              >
                <Tooltip message="Approved">
                  <span>Appr.</span>
                </Tooltip>
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger colName="id" sortIndicatorProps={siProps}>
                ID
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="type"
                sortIndicatorProps={siProps}
                filterable
              >
                Type
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="name" sortIndicatorProps={siProps}>
                Name
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="dataHistory.collectedBy"
                sortIndicatorProps={siProps}
                filterable
              >
                Author
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="qualityParameters.publicationType"
                sortIndicatorProps={siProps}
              >
                Pub. Type
              </SortTrigger>
            </th>
            <th>
              <Tooltip message="# Measurements / # AEs">
                <SortTrigger
                  colName="measurementCount"
                  sortIndicatorProps={siProps}
                >
                  Meas.
                </SortTrigger>
              </Tooltip>
            </th>
            <th>
              <SortTrigger
                colName="hasOutcrops"
                sortIndicatorProps={siProps}
                filterable
                renderFilterOption={value =>
                  value === 'true'
                    ? 'Has linked outcrops'
                    : 'No linked outcrops'
                }
              >
                Outcrops
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="project.name"
                sortIndicatorProps={siProps}
                filterable
              >
                Project
              </SortTrigger>
            </th>
            <th className="text-center">
              <Tooltip message="Whether the study has been merged or needs to be QC'd.">
                <SortTrigger
                  colName="qcCompleted"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  QC
                </SortTrigger>
              </Tooltip>
            </th>
            <th className="col-span-2/12">{/* Actions */}</th>
          </tr>
        </thead>

        <tbody>
          <NoItemsRow colSpan={9} show={!studies.length} />
          {studies.map(s => (
            <StudyListItem key={s.id} study={s} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default StudyList;
