import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDebounce } from '~/hooks/debounce';

type Props = {
  /** Delay in milliseconds to debounce form values. Default = 300 */
  debounceDelay?: number;
};

export function FormikAutosave({ debounceDelay = 300 }: Props) {
  const { values, submitForm } = useFormikContext();

  const debouncedValues = useDebounce(values, debounceDelay);

  useEffect(() => {
    console.log('Values changed, submitting form...');
    submitForm();
  }, [debouncedValues, submitForm]);

  return null;
}
