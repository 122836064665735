import { gql } from '@apollo/client';
import * as R from 'ramda';
import type {
  BasinPartsFragment,
  OutcropPaleogeographyPartsFragment,
} from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { Well } from '~/components/common/Well';
import { AgeTable } from '~/components/geologicalAge/FullAgePopover';
import { PaleomapListItem } from '~/components/outcrop/PaleomapsTab/PaleomapListItem';
import { WikiPopover } from '~/components/wiki/WikiPopover';
import { hasValue } from '~/utils/common';

export const outcropPaleogeographyParts = gql`
  fragment outcropPaleogeographyParts on Outcrop {
    ...outcropParts
    basin {
      ...basinParts
    }
    sourceArea {
      ...sourceAreaParts
    }
    paleoMaps {
      ...paleoMapParts
      pictures {
        ...pictureParts
        file {
          ...fileParts
          signedUrl
        }
      }
    }
    startAge {
      ...lithostratAgeParts
    }
    endAge {
      ...lithostratAgeParts
    }
  }
`;

type Outcrop = OutcropPaleogeographyPartsFragment;
type SourceArea = NonNullable<Outcrop['sourceArea']>;

function hasAnySourceAreaValues(sourceArea: SourceArea | null | undefined) {
  if (!sourceArea) return false;

  return R.any(hasValue, [
    sourceArea.name,
    sourceArea.description,
    sourceArea.geography,
    sourceArea.structure,
    sourceArea.climate,
    sourceArea.distanceToSourceAreaDesc,
    sourceArea.distanceToShorelineDesc,
    sourceArea.distanceToShelfEdgeDesc,
    sourceArea.comments,
  ]);
}

function NonNullRow({
  label,
  value,
  unit,
  otherWikiId,
}: {
  label: string;
  value: string | null;
  unit?: string;
  otherWikiId?: number;
}) {
  if (!hasValue(value)) {
    return null;
  }

  return (
    <tr>
      <td>
        <strong>
          {label}
          {otherWikiId && (
            <>
              {' '}
              <WikiPopover otherWikiId={38} />
            </>
          )}
        </strong>
      </td>
      <td>
        {value}
        {unit && ` ${unit}`}
      </td>
    </tr>
  );
}

export function OutcropPaleomapsTab({
  outcrop,
}: {
  outcrop: OutcropPaleogeographyPartsFragment;
}) {
  const saValue = (key: keyof SourceArea): any =>
    R.pathOr(null, ['sourceArea', key], outcrop);

  const basinValue = (key: keyof BasinPartsFragment): any =>
    R.pathOr(null, ['basin', key], outcrop);

  const hasSourceArea = !R.isNil(outcrop.sourceArea);
  const hasBasin = !R.isNil(outcrop.basin);

  const paleoMaps = outcrop.paleoMaps;
  const hasPaleoMaps = paleoMaps.length > 0;

  const twoColLayout = hasSourceArea && hasBasin ? 6 : 12;

  return (
    <div className="space-y-4">
      {hasPaleoMaps && (
        <>
          {paleoMaps.map(paleoMap => (
            <PaleomapListItem
              key={paleoMap.id}
              outcropId={outcrop.id}
              paleoMap={paleoMap}
            />
          ))}
        </>
      )}

      {(outcrop.startAge || outcrop.endAge) && (
        <Well>
          <div className="grid lg:grid-cols-2 gap-6">
            {outcrop.startAge && (
              <div>
                <Heading level={3}>Start Age</Heading>
                <AgeTable age={outcrop.startAge} />
              </div>
            )}
            {outcrop.endAge && (
              <div>
                <Heading level={3}>End Age</Heading>
                <AgeTable age={outcrop.endAge} />
              </div>
            )}
          </div>
        </Well>
      )}

      <div className="grid lg:grid-cols-2 gap-6">
        {hasAnySourceAreaValues(outcrop.sourceArea) && (
          <div className={twoColLayout ? '' : 'lg:col-span-2'}>
            <Well>
              <Heading level={3}>Source area information</Heading>

              <table className="table table-compact w-full mt-2">
                <tbody>
                  <NonNullRow label="Name" value={saValue('name')} />
                  <NonNullRow
                    label="Source area geography"
                    value={saValue('geography')}
                  />
                  <NonNullRow
                    label="Source area structure"
                    value={saValue('structure')}
                  />
                  <NonNullRow
                    label="Source area climate"
                    value={saValue('climate')}
                  />
                  <NonNullRow
                    label="Estimated distance to source area"
                    value={saValue('distanceToSourceAreaDesc')}
                    otherWikiId={38}
                  />
                  <NonNullRow
                    label="Estimated distance to shoreline"
                    value={saValue('distanceToShorelineDesc')}
                    otherWikiId={38}
                  />
                  <NonNullRow
                    label="Estimated distance to shelf edge"
                    value={saValue('distanceToShelfEdgeDesc')}
                    otherWikiId={38}
                  />

                  {!R.isNil(saValue('description')) && (
                    <tr>
                      <td colSpan={2}>
                        <div>
                          <strong>Description</strong>
                        </div>
                        <div
                          style={{
                            maxWidth: '100',
                            wordBreak: 'break-word',
                          }}
                        >
                          {saValue('description')}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Well>
          </div>
        )}

        {!R.isNil(outcrop.basin) && (
          <div className={twoColLayout ? '' : 'lg:col-span-2'}>
            <Well>
              <Heading level={3}>Basin information</Heading>
              <table className="table table-compact w-full mt-2">
                <tbody>
                  <NonNullRow label="Basin name" value={basinValue('name')} />
                  <NonNullRow
                    label="Basin type"
                    value={basinValue('basinType')}
                  />
                  <NonNullRow
                    label="Catchment area"
                    value={basinValue('catchmentArea')}
                    unit="km²"
                  />
                  <NonNullRow
                    label="Terrestrial/Marine"
                    value={basinValue('terrestrialMarine')}
                  />

                  {!R.isNil(basinValue('description')) && (
                    <tr>
                      <td colSpan={2}>
                        <div>
                          <strong>Description</strong>
                        </div>
                        <div
                          style={{
                            maxWidth: '100%',
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {basinValue('description')}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Well>
          </div>
        )}
      </div>
    </div>
  );
}
