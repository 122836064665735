import { useQuery , gql } from '@apollo/client';
import { Link } from 'react-router-dom';

import * as fragments from '~/apollo/fragments';
import type {
  GeologyWikiIndexRouteQuery,
  GeologyWikiIndexRouteQueryVariables,
} from '~/apollo/generated/schema';
import { FilterSearch } from '~/components/common/FilterSearch';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { ListGroup } from '~/components/common/ListGroup';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useSortFilter } from '~/hooks/data';
import { geologyWikiPageRoute } from '~/paths';
import { sortByArray } from '~/utils/common';

const GEOLOGY_WIKI_INDEX_ROUTE = gql`
  query GeologyWikiIndexRoute {
    depositionalList {
      ...depositionalWikiParts
    }

    gdeLinks: depositionalList(type: GROSS_DEPOSITIONAL_ENVIRONMENT) {
      ...depositionalWikiParts
      diagram {
        ...diagramParts
        file {
          ...fileParts
          signedUrl
        }
      }
    }
  }

  ${fragments.depositionalWikiParts}
  ${fragments.diagramParts}
  ${fragments.fileParts}
`;

export default function GeologyWikiIndexRoute() {
  const { data, loading } = useQuery<
    GeologyWikiIndexRouteQuery,
    GeologyWikiIndexRouteQueryVariables
  >(GEOLOGY_WIKI_INDEX_ROUTE, {});

  const depoList = data?.depositionalList ?? [];
  const { items, filterSearchProps } = useSortFilter(
    depoList,
    'title',
    'title',
    'geologyWikiList',
  );

  const gdeOrder = [
    'Continental (GDE)',
    'Paralic and Shallow Marine',
    'Deep Marine (GDE)',
  ];
  const gdes = sortByArray(data?.gdeLinks ?? [], gdeOrder, x => x.title);

  if (loading) return <SpinnerPlaceholder />;

  return (
    <div className="space-y-4">
      <PageHeading>Geology</PageHeading>

      <p className="text-base">
        The SAFARI Depositional Environment Schema includes pages on all of the
        architectural elements, sub-environments and depositional environments
        in the SAFARI schema.Type a name into the search box below and the press
        enter. The search will return articles with the search function in the
        title. Or simply browse through the list of articles.
      </p>

      <div className="grid lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <FilterSearch
            {...filterSearchProps}
            showAlphabet={false}
            renderSearch={input => input}
          />

          <div className="max-h-screen overflow-y-auto">
            <ListGroup>
              {items.map(wikiPage => (
                <ListGroup.Item key={wikiPage.id}>
                  <Link
                    to={geologyWikiPageRoute(wikiPage.id)}
                    className="w-full h-full flex items-center gap-2"
                  >
                    {wikiPage.geologyType && (
                      <GeologyTypeIcon
                        geologyType={wikiPage.geologyType}
                        className="h-4"
                      />
                    )}
                    <span>{wikiPage.title}</span>
                  </Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </div>

        <div className="space-y-4">
          {gdes.map(article => (
            <Panel key={article.id}>
              <Panel.Heading>
                <Panel.Title className="flex items-center gap-2">
                  {article.geologyType && (
                    <GeologyTypeIcon
                      geologyType={article.geologyType}
                      className="h-4"
                    />
                  )}
                  {article.title}
                </Panel.Title>
              </Panel.Heading>

              <Panel.Body>
                <Link to={geologyWikiPageRoute(article.id)}>
                  {/* Try to render the diagram, or fall back to the geology type icon */}
                  {article.diagram?.file?.signedUrl ? (
                    <img
                      src={article.diagram?.file?.signedUrl}
                      className="mask mask-square"
                      alt="Article diagram"
                    />
                  ) : article.geologyType ? (
                    <GeologyTypeIcon
                      geologyType={article.geologyType}
                      className="w-full h-auto"
                    />
                  ) : null}
                </Link>
              </Panel.Body>
            </Panel>
          ))}
        </div>
      </div>
    </div>
  );
}
