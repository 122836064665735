import { gql, useQuery } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Form, Formik, useFormikContext } from 'formik';
import { createContext, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import type {
  DataSearchMeasurementsQuery,
  DataSearchMeasurementsQueryVariables,
  DataSearchOptionsQuery,
  DataSearchOptionsQueryVariables,
  DataSearchQueryInput,
  DepositionalHierarchyQuery,
  DepositionalHierarchyQueryVariables,
} from '~/apollo/generated/schema';
import { DEPOSITIONAL_HIERARCHY } from '~/apollo/operations/depositional';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useImperativeQuery } from '~/hooks/apollo';
import type {
  DataSearchFormValues,
  DataSearchOptions,
} from '~/utils/modules/dataSearch';
import {
  dataSearchInitialValues,
  dataSearchValidationSchema,
  formValuesToOptionsInput,
  initialDataSearchOptions,
  updateCounts,
} from '~/utils/modules/dataSearch';

export const DATA_SEARCH_OPTIONS = gql`
  query DataSearchOptions($params: DataSearchQueryInput!) {
    dataSearchOptions(params: $params) {
      geologyType {
        name
        count
      }
      outcropCategory {
        name
        count
      }
      grossDepositionalEnvironment {
        name
        count
      }
      depositionalEnvironment {
        name
        count
      }
      depositionalSubEnvironment {
        name
        count
      }
      architecturalElement {
        name
        count
      }
      basinType {
        name
        count
      }
      climate {
        name
        count
      }
      country {
        name
        count
      }
      startAge {
        name
        count
      }
      netToGross {
        name
        count
      }
      distanceToSourceAreaDesc {
        name
        count
      }

      measurementCompletenessX {
        name
        count
      }
      measurementCompletenessY {
        name
        count
      }
      dataTypeX {
        name
        count
      }
      dataTypeY {
        name
        count
      }
      measurementQualityX {
        name
        count
      }
      measurementQualityY {
        name
        count
      }

      outcrops {
        id
        name
        region {
          id
          name
          location {
            id
            country
          }
        }
      }

      studies {
        id
        name
        dataHistory {
          id
          collectedBy
          date
        }
        outcrops {
          id
        }
      }
    }
  }
`;

export const DATA_SEARCH_MEASUREMENTS = gql`
  query DataSearchMeasurements($params: DataSearchQueryInput!) {
    dataSearchMeasurements(params: $params) {
      architecturalElement
      outcropId
      outcropName
      grossDepositionalEnvironment
      depositionalEnvironment
      depositionalSubEnvironment
      basinType
      climate
      netToGross
      distanceToSourceAreaDesc
      outcropCategory
      x
      y
    }
  }
`;

type DataSearchContextValue = {
  hierarchy: DepositionalHierarchyQuery['depositionalHierarchyFull'] | null;
  measurements: DataSearchMeasurementsQuery['dataSearchMeasurements'];
  loadingMeasurements: boolean;
  clearMeasurements: () => void;
  options: DataSearchOptions;
  loadingOptions: boolean;
  /** A cached search query, so that the options for the current measurement search are always known */
  searchInput: DataSearchQueryInput;
};

export const DataSearchContext = createContext<DataSearchContextValue>({
  hierarchy: null,
  measurements: [],
  loadingMeasurements: false,
  clearMeasurements: () => {},
  options: initialDataSearchOptions(),
  loadingOptions: false,
  searchInput: formValuesToOptionsInput(dataSearchInitialValues()),
});

function DataSearchContextProviderInner({
  children,
  hierarchy,
  measurements,
  loadingMeasurements,
  clearMeasurements,
  searchInput,
}: {
  children: React.ReactNode;
  hierarchy: DepositionalHierarchyQuery['depositionalHierarchyFull'];
  measurements: DataSearchMeasurementsQuery['dataSearchMeasurements'];
  loadingMeasurements: boolean;
  clearMeasurements: () => void;
  searchInput: DataSearchQueryInput;
}) {
  const { values } = useFormikContext<DataSearchFormValues>();

  const [options, setOptions] = useState<DataSearchOptions>(
    initialDataSearchOptions(),
  );

  const { loading: loadingOptions } = useQuery<
    DataSearchOptionsQuery,
    DataSearchOptionsQueryVariables
  >(DATA_SEARCH_OPTIONS, {
    variables: { params: formValuesToOptionsInput(values) },
    onCompleted(data) {
      const curOptions = data.dataSearchOptions;
      // Each time options are loaded, update the counts stored in state
      setOptions(prevOptions => updateCounts(prevOptions, curOptions));
    },
  });

  const providerValue: DataSearchContextValue = {
    hierarchy,
    measurements,
    loadingMeasurements,
    clearMeasurements,
    options,
    loadingOptions,
    searchInput,
  };

  return (
    <DataSearchContext.Provider value={providerValue}>
      {children}
    </DataSearchContext.Provider>
  );
}

function DataSearchContextProviderFormikWrapper({
  children,
  initialValues = dataSearchInitialValues(),
  initialOptions = initialDataSearchOptions(),
  initialMeasurements = [],
}: {
  children: React.ReactNode;
  initialValues?: DataSearchFormValues | undefined;
  initialOptions?: DataSearchOptions;
  initialMeasurements?: DataSearchMeasurementsQuery['dataSearchMeasurements'];
}) {
  const [cachedValues, setCachedValues] = useState(
    formValuesToOptionsInput(dataSearchInitialValues()),
  );

  // We need to track this manually because we reset the form on submit
  const [submitCount, setSubmitCount] = useState(0);

  const { data: dataHierarchy, loading: loadingHierarchy } = useQuery<
    DepositionalHierarchyQuery,
    DepositionalHierarchyQueryVariables
  >(DEPOSITIONAL_HIERARCHY);

  const [measurements, setMeasurements] =
    useState<DataSearchMeasurementsQuery['dataSearchMeasurements']>(
      initialMeasurements,
    );

  const [loadMeasurements, { loading: loadingMeasurements }] =
    useImperativeQuery<
      DataSearchMeasurementsQuery,
      DataSearchMeasurementsQueryVariables
    >(DATA_SEARCH_MEASUREMENTS);

  const clearMeasurements = useCallback(() => {
    setMeasurements([]);
  }, [setMeasurements]);

  async function handleSubmit(
    values: DataSearchFormValues,
    helpers: FormikHelpers<DataSearchFormValues>,
  ) {
    try {
      const params = formValuesToOptionsInput(values);
      const result = await loadMeasurements({ variables: { params } });
      if (result.data?.dataSearchMeasurements.length) {
        setCachedValues(params);
        setMeasurements(result.data.dataSearchMeasurements);
      } else {
        clearMeasurements();
        toast.warn(
          "There isn't enough data for the selected filters. Try selecting different data types to plot or removing some filters.",
        );
      }
    } catch (err) {
      console.log('Error loading measurements', err);
      clearMeasurements();
    } finally {
      setSubmitCount(submitCount + 1);
      helpers.resetForm({ values, submitCount: submitCount + 1 });
    }
  }

  if (loadingHierarchy) {
    return <SpinnerPlaceholder>Initializing data search...</SpinnerPlaceholder>;
  }
  if (!dataHierarchy) {
    console.log('Error loading hierarchy');
    return null;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={dataSearchValidationSchema}
    >
      <Form>
        <DataSearchContextProviderInner
          hierarchy={dataHierarchy.depositionalHierarchyFull}
          measurements={measurements}
          loadingMeasurements={loadingMeasurements}
          clearMeasurements={clearMeasurements}
          searchInput={cachedValues}
        >
          {children}
        </DataSearchContextProviderInner>
      </Form>
    </Formik>
  );
}

export const DataSearchContextProvider = DataSearchContextProviderFormikWrapper;

export function useDataSearchContext() {
  return useContext(DataSearchContext);
}

/** Wrapper for the hierarchy that asserts not-null */
export function useDataSearchHierarchy() {
  const ctx = useDataSearchContext();

  if (!ctx.hierarchy) {
    throw new Error('Error loading data search hierarchy');
  }

  return ctx.hierarchy;
}
