import { gql } from '@apollo/client';

export const userParts = gql`
  fragment userParts on User {
    id
    name
    companyId
    accountLocked
    enabled
    insertedAt
    isCompanyAdmin
    username
    activityCount
    lastActivity
  }
`;
export const authorityUserParts = gql`
  fragment authorityUserParts on AuthorityUser {
    id
    name
    companyId
    accountLocked
    enabled
    insertedAt
    isCompanyAdmin
    username
    activityCount
    lastActivity
    isSocialLogin
  }
`;

export const authorityParts = gql`
  fragment authorityParts on Authority {
    user {
      ...authorityUserParts
      company {
        ...companyParts
      }
    }
    roles
  }
`;

export const companyAdminUserParts = gql`
  fragment companyAdminUserParts on CompanyAdminUser {
    id
    name
    username
  }
`;

export const pageParts = gql`
  fragment pageParts on Page {
    id
    pageName
    title
    content
    secured
  }
`;

export const projectParts = gql`
  fragment projectParts on Project {
    id
    name
    comments
    description
    hidden
    safariRole
    insertedAt
    updatedAt
  }
`;

export const studyParts = gql`
  fragment studyParts on Study {
    id
    name
    projectId
    comments
    orientation
    depositionalDipDirection
    public
    accessibility
    approved
    readyForApproval
    type
    abstract
    keyReferences
    qcCompleted
    redundant
    dominantGeologyType
    keywords
    publicationLicense
    numericalDataAspects
    updatedAt
  }
`;

export const aeParts = gql`
  fragment aeParts on ArchitecturalElement {
    id
    name
    connectivity
    comments
    description
    architecturalElement
    depositionalConfinement
    outcropOrder
    background
    grossDepositionalEnvironment
    depositionalEnvironment
    depositionalSubEnvironment
    shapeKind
    measurementCount
    outcropId
    studyId
    geologyType

    channelMorphology
    diageneticGeometry
    diageneticProcess
    diageneticSetting
    dominantLithology
    duneShape
    faultRocksMembranes
    interactionNetwork
    multipleFolds
    reactivation1stPhase
    reactivation2ndPhase
    riverProfileLocation
    secondaryStructures
    shorelineTractory
    symmetryGeometry
    synSedimentaryDeformation
    systemsTract
    tectonicSetting
    waterTemperature
    lateralAggregation
  }
`;

export const measurementParts = gql`
  fragment measurementParts on ArchitecturalMeasurement {
    completeness
    dataType
    id
    outcropArchitecturalElementId
    quality
    value
  }
`;

export const outcropParts = gql`
  fragment outcropParts on Outcrop {
    id
    regionId
    name
    description
    introduction
    readyForApproval
    approved
    outcropType
    geologyType
    location
    environments
    stratigraphy
    notablesAndAnalogues
    location
    safari
    orientation
    depositionalDipDirection
    qcCompleted
    outcropCategory
    keyWords
    diagenesisAndPetrophysicalProperties
    structuralGeology
    dominantGeologyType
    systemsTract
    shorelineTractory
    duneShape
    channelMorphology
    riverProfileLocation
    dominantLithology
    waterTemperature
    netToGross
    diageneticSetting
    diageneticProcess
    diageneticGeometry
    tectonicSetting
    synSedimentaryDeformation
    faultRocksMembranes
    interactionNetwork
    reactivation1stPhase
    reactivation2ndPhase
    symmetryGeometry
    multipleFolds
    secondaryStructures
    lateralAggregation
    updatedAt
    author
  }
`;

export const otherWikiParts = gql`
  fragment otherWikiParts on OtherWiki {
    id
    text
    title
    type
    value
    insertedAt
    updatedAt
  }
`;

export const depositionalWikiParts = gql`
  fragment depositionalWikiParts on DepositionalWiki {
    id
    title
    intro
    description
    diagnostic
    reservoir
    subsurface
    mafLink
    modeling
    geologyType
    type
    value
    author
    modernExample
    references
    published
    readyForApproval
    vomExampleId
    insertedAt
    updatedAt
  }
`;

export const pictureParts = gql`
  fragment pictureParts on Picture {
    id
    outcropTagId
    author
    category
    comments
    description
    name
    pictureOrientation
    scale
    type
    priority
    published
  }
`;

export const fileParts = gql`
  fragment fileParts on File {
    id
    name
    category
    contentType
    public
    s3Name
    size
    signedUrl
  }
`;

export const lithostratAgeParts = gql`
  fragment lithostratAgeParts on LithostratAge {
    id
    comments
    eon
    era
    series
    stage
    system
  }
`;

export const basinParts = gql`
  fragment basinParts on Basin {
    id
    name
    description
    climate
    basinType
    catchmentArea
    terrestrialMarine
    waterDepth
    accommodationRegime
    comments
  }
`;

export const outcropQualityParametersParts = gql`
  fragment outcropQualityParametersParts on QualityParameters {
    id
    datasetScale
    exposureQuality
    outcrop_3d_control
    structuralComplexity
  }
`;

export const keyParametersParts = gql`
  fragment keyParametersParts on KeyParameters {
    id
    geologyType
    grossDepositionalEnvironment
    depositionalEnvironment
    depositionalSubEnvironment
    architecturalElement
    valid
    dominant
  }
`;

export const keyParametersFromAeParts = gql`
  fragment keyParametersFromAeParts on KeyParametersFromAe {
    id
    geologyType
    grossDepositionalEnvironment
    depositionalEnvironment
    depositionalSubEnvironment
    architecturalElement
  }
`;

export const vomParts = gql`
  fragment vomParts on VirtualOutcropModel {
    id
    name
    projectId
    outcropId
    acquisitionEquipment
    acquisitionType
    acquisitionYear
    approved
    aquiringPerson
    basinType
    climate
    comments
    copyright
    copyrightLogo
    notableAspects
    offset
    processingPerson
    processingYear
    quality
    size
    smallestVisibleFeature
    v3GeoId
    priority
  }
`;

export const regionParts = gql`
  fragment regionParts on Region {
    id
    name
    description
    basins
    insertedAt
    updatedAt
    location {
      id
      location
      region
      country
    }
  }
`;

export const georeferenceParts = gql`
  fragment georeferenceParts on Georeference {
    id
    name
    description
    dataType
    data {
      lat
      lng
    }
  }
`;

export const sourceAreaParts = gql`
  fragment sourceAreaParts on SourceArea {
    id
    name
    description
    geography
    structure
    climate
    distanceToSourceArea
    distanceToSourceAreaDesc
    distanceToShoreline
    distanceToShorelineDesc
    distanceToShelfEdge
    distanceToShelfEdgeDesc
    comments
  }
`;

export const dataHistoryParts = gql`
  fragment dataHistoryParts on DataHistory {
    id
    collectedBy
    date
  }
`;

export const studyQualityParametersParts = gql`
  fragment studyQualityParametersParts on QualityParameters {
    id
    dataAcquisitionMethod
    publicationType
    spatialObservationType
  }
`;

export const lithostratOutcropLinkParts = gql`
  fragment lithostratOutcropLinkParts on LithostratOutcropLink {
    id
    targetId
    targetName
    targetType
  }
`;

export const lithostratStudyLinkParts = gql`
  fragment lithostratStudyLinkParts on LithostratStudyLink {
    id
    targetId
    targetName
    targetType
  }
`;

export const crossSectionParts = gql`
  fragment crossSectionParts on CrossSection {
    id
    outcropTagId
    name
    author
    comments
    description
    scale
    published
    priority
  }
`;

export const faciesParts = gql`
  fragment faciesParts on Facies {
    id
    outcropTagId
    name
    description
    author
    interpretation
    comments
    published
    priority
  }
`;

export const sedimentaryLogParts = gql`
  fragment sedimentaryLogParts on SedimentaryLog {
    id
    outcropTagId
    name
    author
    comments
    description
    scale
    published
    priority
  }
`;

export const wellLogParts = gql`
  fragment wellLogParts on WellLog {
    id
    outcropTagId
    name
    author
    comments
    description
    published
    priority
  }
`;

export const productionParts = gql`
  fragment productionParts on Production {
    id
    outcropTagId
    name
    author
    comments
    description
    published
    priority
  }
`;

export const reservoirModelParts = gql`
  fragment reservoirModelParts on ReservoirModel {
    id
    outcropTagId
    name
    author
    comments
    description
    published
    priority
  }
`;

export const trainingImageParts = gql`
  fragment trainingImageParts on TrainingImage {
    id
    outcropTagId
    name
    author
    comments
    description
    published
    priority
  }
`;

export const variogramParts = gql`
  fragment variogramParts on Variogram {
    id
    outcropTagId
    name
    author
    comments
    description
    nugget
    sill
    range
    published
    priority
  }
`;

export const gigaPanParts = gql`
  fragment gigaPanParts on GigaPan {
    id
    outcropTagId
    name
    gigaPanHash
    published
    priority
  }
`;

export const savedDataSearchParts = gql`
  fragment savedDataSearchParts on SavedDataSearch {
    architecturalElement
    basinType
    binWidth
    climate
    country
    dataTypeX
    dataTypeY
    depositionalEnvironment
    depositionalSubEnvironment
    geologyType
    graphType
    grossDepositionalEnvironment
    netToGross
    distanceToSourceAreaDesc
    groupDataBy
    id
    insertedAt
    logScaleX
    logScaleY
    maxX
    measurementCompletenessX
    measurementCompletenessY
    measurementQualityX
    measurementQualityY
    minX
    name
    niceBins
    numBins
    outcropIds
    studyIds
    revisionCount
    showRegression
    showRegressionThroughOrigin
    startAge
    userId
    companyId
    outcropCategory
  }
`;

export const dataSearchMeasurementParts = gql`
  fragment dataSearchMeasurementParts on DataSearchMeasurement {
    architecturalElement
    outcropId
    architecturalElement
    basinType
    climate
    depositionalEnvironment
    depositionalSubEnvironment
    grossDepositionalEnvironment
    outcropName
    outcropCategory
    netToGross
    distanceToSourceAreaDesc
    x
    y
  }
`;

// Separate fragment for the data so it doensn't always need to be included...
// The measurements can be quite long and inflate the response size
export const savedDataSearchDataParts = gql`
  fragment savedDataSearchDataParts on SavedDataSearchData {
    id
    insertedAt
    revision
    savedDataSearchId
    measurements {
      ...dataSearchMeasurementParts
    }
  }

  ${dataSearchMeasurementParts}
`;

export const bookmarkParts = gql`
  fragment bookmarkParts on Bookmark {
    id
    userId
    companyId
    path
    parentType
    targetType
    note
    insertedAt
    updatedAt
  }
`;

export const bookmarkParentParts = gql`
  fragment bookmarkParentParts on BookmarkParent {
    ... on Outcrop {
      id
      name
    }
    ... on Region {
      id
      name
    }
    ... on Study {
      id
      name
    }
    ... on PaleoMap {
      id
      description
    }
    ... on DepositionalWiki {
      id
      title
    }
    ... on OtherWiki {
      id
      title
    }
    ... on LithostratFormation {
      id
      name
    }
    ... on LithostratGroup {
      id
      name
    }
    ... on LithostratMember {
      id
      name
    }
    ... on Facies {
      id
      name
    }
    ... on CrossSection {
      id
      name
    }
    ... on SedimentaryLog {
      id
      name
    }
    ... on WellLog {
      id
      name
    }
    ... on Production {
      id
      name
    }
    ... on ReservoirModel {
      id
      name
    }
    ... on TrainingImage {
      id
      name
    }
    ... on Variogram {
      id
      name
    }
    ... on GigaPan {
      id
      name
    }
    ... on SavedDataSearch {
      id
      name
      dataTypeX
      dataTypeY
      graphType
    }
  }
`;
export const bookmarkTargetParts = gql`
  fragment bookmarkTargetParts on BookmarkTarget {
    ... on Outcrop {
      id
      name
    }
    ... on Region {
      id
      name
    }
    ... on Study {
      id
      name
    }
    ... on VirtualOutcropModel {
      id
      name
      interpreted
      acquisitionType
      outcropId
      pictures {
        ...pictureParts
        file {
          ...fileParts
          signedUrl
        }
      }
      cesiumAsset {
        approved
      }
    }
    ... on Picture {
      ...pictureParts
      file {
        ...fileParts
        signedUrl
      }
    }
    ... on GigaPan {
      id
      name
    }
    ... on DepositionalWiki {
      id
      title
    }
    ... on OtherWiki {
      id
      title
    }
    ... on LithostratFormation {
      id
      name
    }
    ... on LithostratGroup {
      id
      name
    }
    ... on LithostratMember {
      id
      name
    }
    ... on SavedDataSearch {
      id
      name
      dataTypeX
      dataTypeY
      graphType
    }
  }
`;

export const bookmarkCollectionParts = gql`
  fragment bookmarkCollectionParts on BookmarkCollection {
    id
    userId
    companyId
    name
    description
    insertedAt
    updatedAt
  }
`;

export const bookmarkCollectionBookmarkParts = gql`
  fragment bookmarkCollectionBookmarkParts on BookmarkCollectionBookmark {
    id
    bookmarkCollectionId
    bookmarkId
    includedInReport
    reportOrder
  }
`;

export const publicUserParts = gql`
  fragment publicUserParts on PublicUser {
    name
  }
`;

export const companyParts = gql`
  fragment companyParts on Company {
    id
    name
    mails
    allowRegister
    safariLevel
    lastActivity
    activityCount
    isAadConfigured
  }
`;

export const lithostratTypeParts = gql`
  fragment lithostratTypeParts on LithostratType {
    id
    name
    description
  }
`;

export const diagramAreaParts = gql`
  fragment diagramAreaParts on DiagramArea {
    id
    label
    linkTargetType
    linkTargetId
    path {
      x
      y
    }
  }
`;

export const diagramParts = gql`
  fragment diagramParts on Diagram {
    id
  }
`;

export const lithostratFormationParts = gql`
  fragment lithostratFormationParts on LithostratFormation {
    comments
    description
    id
    lithostratOrder
    name
    lithostratTypeId
  }
`;

export const lithostratGroupParts = gql`
  fragment lithostratGroupParts on LithostratGroup {
    comments
    description
    id
    name
    lithostratTypeId
  }
`;

export const lithostratMemberParts = gql`
  fragment lithostratMemberParts on LithostratMember {
    comments
    description
    id
    lithostratOrder
    name
    lithostratTypeId
  }
`;

export const lithostratWikiPageParts = gql`
  fragment lithostratWikiPageParts on LithostratWikiPage {
    alternativeNames
    analogues
    basinType
    climate
    country
    depositionalEnvironment
    depositionalSubEnvironment
    distributionThickness
    geologyType
    grossDepositionalEnvironment
    id
    introduction
    literatureReferences
    lithologyDepositional
    lithologySequence
    name
    published
    region
    insertedAt
    updatedAt
  }
`;

export const outcropLinkAnalogueParts = gql`
  fragment outcropLinkAnalogueParts on Outcrop {
    ...outcropParts
    thumbnail {
      ...fileParts
      signedUrl
    }
    region {
      ...regionParts
    }
  }

  ${outcropParts}
  ${fileParts}
  ${regionParts}
`;

export const issueParts = gql`
  fragment issueParts on Issue {
    id
    content
    dateCreated
    issueType
    title
    workStatus
  }
`;

export const issueCommentParts = gql`
  fragment issueCommentParts on IssueComment {
    id
    content
    dateCreated
  }
`;

export const publicCompanyParts = gql`
  fragment publicCompanyParts on PublicCompany {
    name
  }
`;

export const literatureParts = gql`
  fragment literatureParts on LiteratureReference {
    id
    author
    comments
    digitalObjectIdentifier
    outcropTagId
    publicationSource
    publicationType
    title
    webSource
    year
    published
    priority
  }
`;

export const paleoMapParts = gql`
  fragment paleoMapParts on PaleoMap {
    id
    description
  }
`;

export const studyAccessParts = gql`
  fragment studyAccessParts on StudyAccess {
    id
  }
`;

export const reviewCommentParts = gql`
  fragment reviewCommentParts on ReviewComment {
    id
    comment
    insertedAt
    parentId
    parentName
    parentType
    authorId
    updatedAt
  }
`;

export const interpretationParts = gql`
  fragment interpretationParts on VirtualOutcropInterpretation {
    id
    published
    virtualOutcropModelId
    studyId
  }
`;

export const auditLogParts = gql`
  fragment auditLogParts on AuditLog {
    id
    userId
    action
    entity
    outcropId
    studyId
    insertedAt
  }
`;

export const fieldPictureParts = gql`
  fragment fieldPictureParts on FieldPicture {
    author
    description
    fileId
    id
    insertedAt
    location {
      lat
      lng
    }
    locationApproximate
    outcropId
    updatedAt
    published
  }
`;

export const supplementalDocumentParts = gql`
  fragment supplementalDocumentParts on SupplementalDocument {
    id
    outcropId
    studyId
    fileId
    note
    insertedAt
    updatedAt
  }
`;

export const cesiumAssetParts = gql`
  fragment cesiumAssetParts on CesiumAsset {
    id
    approved
    state
    virtualOutcropModelId
  }
`;

export const reportParts = gql`
  fragment reportParts on Report {
    companyId
    description
    id
    insertedAt
    title
    updatedAt
    userId
    state
    completedAt
  }
`;

export const reportItemParts = gql`
  fragment reportItemParts on ReportItem {
    id
    reportId
    bookmarkId
    itemType
    order
    title
    text
    insertedAt
    updatedAt

    outcropShowDescription
    outcropShowIntroduction
    outcropShowEnvironments
    outcropShowStratigraphy
    outcropShowDiagenesisAndPetrophysicalProperties
    outcropShowStructuralGeology
    outcropShowNotablesAndAnalogues
    outcropShowLocation
    outcropShowSafari
    outcropShowKeyParameters
    outcropShowPaleogeography
    studyShowAbstract
  }
`;

export const reportItemSummaryParts = gql`
  fragment reportItemSummaryParts on ReportItemSummary {
    bookmarkCount
    textCount
  }
`;

export const dmvParts = gql`
  fragment dmvParts on DefaultMeasurementsView {
    id
    graphType
    crossPlotDataTypeX
    crossPlotDataTypeY
    crossPlotLogScaleX
    crossPlotLogScaleY
    histogramDataType
  }
`;
