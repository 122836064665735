import { useQuery , gql } from '@apollo/client';
import { faFlag, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { camelize, decamelize } from 'humps';
import * as R from 'ramda';
import { Badge } from 'react-daisyui';

import { depositionalWikiParts } from '~/apollo/fragments';
import type {
  DepositionalWikiPartsFragment,
  UploadDepositionalWikiListQuery,
} from '~/apollo/generated/schema';
import { FilterSearch } from '~/components/common/FilterSearch';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useSortFilter } from '~/hooks/data';
import { uploadGeologyCreateRoute, uploadGeologyUpdateRoute } from '~/paths';
import { capitalize, snakeCapsToHuman } from '~/utils/text';

const UPLOAD_DEPOSITIONAL_WIKI_LIST = gql`
  query UploadDepositionalWikiList {
    depositionalList {
      ...depositionalWikiParts
      diagram {
        id
      }
    }
  }

  ${depositionalWikiParts}
`;

const YesIcon = () => <EnabledIndicator value={true} />;
const NoIcon = () => <EnabledIndicator value={false} />;
const ReadyIcon = () => (
  <FontAwesomeIcon icon={faFlag} className="text-warning" />
);

type PublishState = 'published' | 'unpublished' | 'ready for approval';
type SortableDepositionalWiki = DepositionalWikiPartsFragment & {
  hasDiagram: boolean;
  publishState: PublishState;
};

export default function UploadDepositionalListRoute() {
  useBreadcrumb('routes/upload/depositional', 'Geology Wiki Pages');

  const { data, loading } = useQuery<UploadDepositionalWikiListQuery>(
    UPLOAD_DEPOSITIONAL_WIKI_LIST,
    {},
  );

  const publishState = (dw: DepositionalWikiPartsFragment): PublishState => {
    if (dw.published) return 'published';
    if (dw.readyForApproval) return 'ready for approval';
    return 'unpublished';
  };

  const depositionalList = data?.depositionalList ?? [];
  const sortableWikis: SortableDepositionalWiki[] = depositionalList.map(
    dw => ({
      ...dw,
      hasDiagram: !!dw.diagram?.id,
      publishState: publishState(dw),
    }),
  );

  const {
    items,
    filterSearchProps,
    sortIndicatorProps: siProps,
  } = useSortFilter(sortableWikis, 'title', 'title', 'uploadDepositionalList');

  const wikiType = (t: string) =>
    R.pipe(
      R.toLower,
      camelize,
      val => decamelize(val, { separator: ' ' }),
      capitalize,
    )(t);

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <PageHeading>Geology Wiki Pages</PageHeading>

      <Panel>
        <Panel.Heading className="flex justify-between items-center">
          <Panel.Title>
            Wiki List
            {!loading && (
              <small className="text-muted" style={{ marginLeft: '10px' }}>
                (<strong>{items.length}</strong> results displayed)
              </small>
            )}
          </Panel.Title>

          <Link
            to={uploadGeologyCreateRoute()}
            className="btn btn-primary btn-xs gap-1"
          >
            <FontAwesomeIcon icon={faPlus} /> Create Wiki Page
          </Link>
        </Panel.Heading>

        <Panel.Body>
          <FilterSearch {...filterSearchProps} stick={false} />

          {!loading && (
            <table className="table table-compact w-full">
              <thead>
                <tr>
                  <th>
                    <SortTrigger colName="id" sortIndicatorProps={siProps}>
                      ID
                    </SortTrigger>
                  </th>
                  <th className="w-1/12">
                    <SortTrigger
                      colName="geologyType"
                      sortIndicatorProps={siProps}
                      filterable
                    >
                      Geo. Type
                    </SortTrigger>
                  </th>
                  <th className="w-3/12">
                    <SortTrigger colName="title" sortIndicatorProps={siProps}>
                      Title
                    </SortTrigger>
                  </th>
                  <th className="w-2/12">
                    <SortTrigger
                      colName="type"
                      sortIndicatorProps={siProps}
                      filterable
                      renderFilterOption={snakeCapsToHuman}
                    >
                      Type
                    </SortTrigger>
                  </th>
                  <th className="w-2/12">
                    <SortTrigger colName="value" sortIndicatorProps={siProps}>
                      Value
                    </SortTrigger>
                  </th>
                  <th className="w-1/12 text-center">
                    <SortTrigger
                      colName="hasDiagram"
                      sortIndicatorProps={siProps}
                      filterable
                    >
                      Diagram
                    </SortTrigger>
                  </th>
                  <th className="w-1/12 text-center">
                    <SortTrigger
                      colName="publishState"
                      sortIndicatorProps={siProps}
                      filterable
                    >
                      Published
                    </SortTrigger>
                  </th>
                  <th className="w-1/12" />
                </tr>
              </thead>

              <tbody>
                {items.map(wiki => (
                  <tr key={wiki.id}>
                    <td>{wiki.id}</td>
                    <td>
                      {wiki.geologyType && (
                        <span>
                          <GeologyTypeIcon
                            geologyType={wiki.geologyType}
                            className="h-5 inline"
                            hideTooltip
                          />{' '}
                          {wiki.geologyType}
                        </span>
                      )}
                    </td>
                    <td>{wiki.title}</td>
                    <td>{wikiType(wiki.type ?? '')}</td>
                    <td>
                      {wiki.value?.map(value => (
                        <Badge
                          key={value}
                          color="ghost"
                          className="text-sm block h-auto"
                        >
                          {value}
                        </Badge>
                      ))}
                    </td>
                    <td className="text-center">
                      {wiki.hasDiagram ? <YesIcon /> : <NoIcon />}
                    </td>
                    <td className="text-center">
                      {wiki.publishState === 'published' && <YesIcon />}
                      {wiki.publishState === 'unpublished' && <NoIcon />}
                      {wiki.publishState === 'ready for approval' && (
                        <Tooltip message="This wiki page is ready to be reviewed and published.">
                          <span>
                            <ReadyIcon />
                          </span>
                        </Tooltip>
                      )}
                    </td>
                    <td className="text-right">
                      <Link
                        to={uploadGeologyUpdateRoute(wiki.id)}
                        className="btn btn-ghost btn-sm gap-1"
                      >
                        <FontAwesomeIcon icon={faPencil} /> Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Panel.Body>
      </Panel>
    </>
  );
}
