import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input } from 'react-daisyui';
import type { DepositionalHierarchy } from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';
import { InputGroup } from '~/components/common/InputGroup';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/common/Popover';
import { useDebounce } from '~/hooks/debounce';
import { cn } from '~/utils/common';
import {
  collateHierarchyOptions,
  type DataSearchFormValues,
  type DataSearchOptions,
} from '~/utils/modules/dataSearch';
import { FilterItemText } from './DataSearchFilter';
import { useDataSearchContext } from '~/components/dataSearch/dataSearchContext';
import { SpinnerIcon } from '~/components/common/SpinnerIcon';

const FIELD_NAME = 'architecturalElement';

export function AEFilter({
  options,
  values,
  hierarchy,
}: {
  options: DataSearchOptions['architecturalElement'];
  values: string[];
  hierarchy: DepositionalHierarchy;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [aeSearch, setAeSearch] = useState('');
  const { setFieldValue } = useFormikContext<DataSearchFormValues>();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { loadingOptions } = useDataSearchContext();

  /** Local copy of AE changes that will be applied when the popover is closed */
  const [batchedAEs, setBatchedAEs] = useState(values);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const clearBtnRef = useRef<HTMLButtonElement | null>(null);

  // Make sure local AEs stays in sync when prop changes
  useEffect(() => {
    setBatchedAEs(values);
  }, [values]);

  function openPopover() {
    setSelectedIndex(null);
    setIsPopoverOpen(true);
  }

  function closePopover() {
    // Apply any batched AEs to the form
    setFieldValue(FIELD_NAME, batchedAEs);

    // Clear the text input when the popover is closed
    setAeSearch('');

    setIsPopoverOpen(false);
  }

  // Debounce the search value unless it's reset
  const debouncedAeSearch = useDebounce(aeSearch, aeSearch.length ? 500 : 0);

  const hierarchyOptions = useMemo(() => {
    return collateHierarchyOptions(hierarchy);
  }, [hierarchy]);

  const getCount = (value: string) => {
    return options.find(opt => opt.name === value)?.count ?? 0;
  };

  const hierarchyAEOptions = hierarchyOptions.ae.filter(ae => getCount(ae) > 0);

  const filteredOptions = useMemo(() => {
    const searchValue = debouncedAeSearch.trim().toLowerCase();

    if (searchValue.length === 0) {
      return hierarchyAEOptions;
    } else if (searchValue.length === 1) {
      return hierarchyAEOptions.filter(opt =>
        opt.toLowerCase().startsWith(searchValue),
      );
    } else {
      return hierarchyAEOptions.filter(opt =>
        opt.toLowerCase().includes(searchValue),
      );
    }
  }, [debouncedAeSearch, hierarchyAEOptions]);

  function batchAE(ae: string) {
    setBatchedAEs(prevAEs => {
      if (prevAEs.includes(ae)) {
        return R.without([ae], prevAEs);
      } else {
        return R.concat(prevAEs, [ae]);
      }
    });
  }

  function handleAEChecked(event: ChangeEvent<HTMLInputElement>) {
    batchAE(event.target.value);
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    setAeSearch(event.target.value);
    setSelectedIndex(null);
    openPopover();
  }

  function handleInputKey(event: KeyboardEvent<HTMLInputElement>) {
    if (isPopoverOpen) {
      if (event.key === 'Escape') {
        closePopover();
      } else if (
        event.key === 'Enter' ||
        (selectedIndex !== null && event.key === ' ')
      ) {
        event.preventDefault();
        const topValue = filteredOptions.at(selectedIndex ?? 0);
        if (topValue) {
          batchAE(topValue);
        }
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        const nextIndex = (selectedIndex ?? -1) + 1;
        if (filteredOptions.at(nextIndex)) {
          setSelectedIndex(nextIndex);
        }
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        const nextIndex = (selectedIndex ?? 0) - 1;
        if (filteredOptions[nextIndex]) {
          setSelectedIndex(nextIndex);
        } else {
          setSelectedIndex(null);
        }
      }
    } else {
      if (event.key === 'ArrowDown') {
        openPopover();
      }
    }
  }

  const isInitializing = loadingOptions && !values.length;

  return (
    <>
      <Popover open={filteredOptions.length > 0 && isPopoverOpen}>
        <InputGroup className="flex justify-between w-full">
          <InputGroup.Addon left>
            {isInitializing ? (
              <SpinnerIcon />
            ) : (
              <FontAwesomeIcon icon={faSearch} />
            )}
          </InputGroup.Addon>
          <PopoverTrigger className="grow">
            <Input
              ref={inputRef}
              type="text"
              value={aeSearch}
              placeholder={
                isInitializing
                  ? 'Initializing Data Search...'
                  : 'Search for an Architectural Element or click to select from dropdown list'
              }
              className="w-full join-item cursor-pointer"
              onChange={handleInputChange}
              onFocus={openPopover}
              onClick={openPopover}
              onKeyDown={handleInputKey}
              disabled={loadingOptions}
            />
          </PopoverTrigger>
          {aeSearch.length > 0 && (
            <InputGroup.Addon right className="p-0">
              <Button
                type="button"
                ref={clearBtnRef}
                startIcon={<FontAwesomeIcon icon={faXmark} />}
                onClick={() => setAeSearch('')}
                color="ghost"
                size="sm"
                className="h-full w-10 rounded-r-md"
              />
            </InputGroup.Addon>
          )}
        </InputGroup>

        <PopoverContent
          className="md:w-[600px] w-[80vw] max-h-[60vh] overflow-scroll shadow-2xl border-2 border-slate-300 bg-white"
          onOpenAutoFocus={event => {
            // Prevent popover from stealing focus from the text input
            event.preventDefault();
          }}
          onInteractOutside={event => {
            // Do nothing when clicking outside the popover instead
            return void event.preventDefault();

            // Close the popover on "interact outside", unless the interaction
            // is with the text input or the clear button
            // const ignoreTargets = [inputRef.current, clearBtnRef.current];
            // if (isPopoverOpen && !ignoreTargets.some(t => t === event.target)) {
            //   closePopover();
            // }
          }}
        >
          <div className="relative">
            {filteredOptions.map((opt, i) => (
              <div
                key={opt}
                className={cn(
                  'border-l-4 pl-2',
                  selectedIndex === i
                    ? 'border-l-sky-500'
                    : 'border-transparent',
                )}
              >
                <Field
                  name="architecturalElement"
                  label={<FilterItemText value={opt} count={getCount(opt)} />}
                  component={FormikField}
                  type="checkbox"
                  value={opt}
                  size="sm"
                  checked={batchedAEs.includes(opt)}
                  onChange={handleAEChecked}
                  disabled={loadingOptions}
                />
              </div>
            ))}

            <div className="sticky -bottom-4 h-12 flex w-full items-end justify-center bg-white">
              <Button
                type="button"
                onClick={() => closePopover()}
                color="ghost"
                size="sm"
                startIcon={<FontAwesomeIcon icon={faSearch} />}
              >
                Search
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}
