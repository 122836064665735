import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import * as fragments from '~/apollo/fragments';
import type {
  UpdateOutropStudiesRouteQuery,
  UpdateOutropStudiesRouteQueryVariables,
} from '~/apollo/generated/schema';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { RemoveStudyLink } from '~/components/upload/outcrop/RemoveStudyLink';
import { uploadStudyCreateRoute, uploadStudyUpdateRoute } from '~/paths';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export const UPDATE_OUTCROP_STUDIES_ROUTE = gql`
  query UpdateOutropStudiesRoute($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      studies {
        ...studyParts
        outcrops {
          id
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.studyParts}
`;

export default function UpdateOutcropStudiesRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UpdateOutropStudiesRouteQuery,
    UpdateOutropStudiesRouteQueryVariables
  >(UPDATE_OUTCROP_STUDIES_ROUTE, {
    variables: { outcropId },
  });

  const refetchQueries: [PureQueryOptions<any>] = [
    {
      query: UPDATE_OUTCROP_STUDIES_ROUTE,
      variables: { outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const studies = R.sortBy(R.propOr(Infinity, 'id'), outcrop?.studies ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <Panel>
      <Panel.Heading className="flex justify-between items-center">
        <Panel.Title>Studies</Panel.Title>
        <Link
          to={`${uploadStudyCreateRoute()}?outcropId=${outcropId}`}
          className="btn btn-primary btn-xs gap-1"
        >
          <FontAwesomeIcon icon={faPlus} /> Create Study
        </Link>
      </Panel.Heading>

      <Panel.Body>
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <NoItemsRow show={!studies.length} colSpan={3} />

            {studies.map(study => (
              <tr key={study.id}>
                <td>{study.id}</td>
                <td>{study.name}</td>
                <td className="text-right">
                  <div className="btn-group">
                    <Link
                      to={uploadStudyUpdateRoute(study.id)}
                      className="btn btn-ghost btn-sm gap-1"
                    >
                      <FontAwesomeIcon icon={faMagnifyingGlass} /> View
                    </Link>
                    <DropdownMenu label="" color="ghost" size="sm">
                      <DropdownMenu.Items>
                        <RemoveStudyLink
                          outcropId={outcropId}
                          studyId={study.id}
                          numOutcrops={study.outcrops.length}
                          refetchQueries={refetchQueries}
                        />
                      </DropdownMenu.Items>
                    </DropdownMenu>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
}
