import * as d3 from 'd3';
import React from 'react';
import {
  mean,
  median,
  mode,
  quantile,
  standardDeviation,
} from 'simple-statistics';
import { Heading } from '~/components/common/Heading';
import { Well } from '~/components/common/Well';

function formatValue(value: number | string): string;
function formatValue(value?: number | null): string | null;
function formatValue(value: number | string | null | undefined) {
  if (typeof value === 'undefined' || value === null) return null;
  if (typeof value === 'string') return value;
  return value.toFixed(2);
}

type StatNumProps = {
  label: string;
  valueX: string | number;
  valueY: string | number | null;
};
function StatNum({ label, valueX, valueY }: StatNumProps) {
  return (
    <div className="text-left">
      <small className="text-muted" style={{ fontSize: '9pt' }}>
        {label}:
      </small>
      <br />
      {/* Don't show 'x' label if y isn't set */}
      {valueY !== null && (
        <small className="text-muted" style={{ fontSize: '9pt' }}>
          x:{' '}
        </small>
      )}
      {formatValue(valueX)}
      {valueY !== null && (
        <>
          <br />
          <small className="text-muted" style={{ fontSize: '9pt' }}>
            y:{' '}
          </small>
          {formatValue(valueY)}
        </>
      )}
    </div>
  );
}

type Props = {
  dataX: number[];
  dataY?: number[] | null;
  children?: React.ReactNode;
};
export function MeasurementStatistics({ dataX, dataY, children }: Props) {
  const calcPercentile = (values: number[]) => (percentile: number) =>
    quantile(values, percentile);

  const [minX, maxX] = d3.extent(dataX);
  const [minY, maxY] = d3.extent(dataY ?? []);

  return (
    <Well className="space-y-2">
      <Heading level={4}>Statistics</Heading>

      {children}

      {(minX || maxX) && (
        <div className="grid lg:grid-cols-4 print:grid-cols-4 gap-6">
          {minX && (
            <StatNum
              label="Minimum"
              valueX={formatValue(minX)}
              valueY={formatValue(minY ?? null)}
            />
          )}
          {maxX && (
            <StatNum
              label="Maximum"
              valueX={formatValue(maxX)}
              valueY={formatValue(maxY)}
            />
          )}
        </div>
      )}

      <div className="grid lg:grid-cols-4 print:grid-cols-4 gap-6">
        <StatNum
          label="P10"
          valueX={calcPercentile(dataX)(0.1)}
          valueY={dataY ? calcPercentile(dataY)(0.1) : null}
        />
        <StatNum
          label="P50"
          valueX={calcPercentile(dataX)(0.5)}
          valueY={dataY ? calcPercentile(dataY)(0.5) : null}
        />
        <StatNum
          label="P90"
          valueX={calcPercentile(dataX)(0.9)}
          valueY={dataY ? calcPercentile(dataY)(0.9) : null}
        />
      </div>

      <div className="grid lg:grid-cols-4 print:grid-cols-4 gap-6">
        <StatNum
          label="Mean"
          valueX={mean(dataX)}
          valueY={dataY ? mean(dataY) : null}
        />
        <StatNum
          label="Median"
          valueX={median(dataX)}
          valueY={dataY ? median(dataY) : null}
        />
        <StatNum
          label="Mode"
          valueX={mode(dataX)}
          valueY={dataY ? mode(dataY) : null}
        />
        <StatNum
          label="Std Deviation"
          valueX={standardDeviation(dataX)}
          valueY={dataY ? standardDeviation(dataY) : null}
        />
      </div>

      <div className="text-center">
        <em className="text-muted">n</em> = {dataX.length}
      </div>
    </Well>
  );
}
