import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import invariant from 'tiny-invariant';
import type {
  MySafariCollectionsPageQuery,
  MySafariCollectionsPageQueryVariables,
} from '~/apollo/generated/schema';
import {
  BookmarkCollectionList,
  bookmarkCollectionListParts,
} from '~/components/bookmark/BookmarkCollectionList';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useAuth } from '~/contexts/auth';

const MY_SAFARI_COLLECTIONS_PAGE = gql`
  query MySafariCollectionsPage($userId: Int!) {
    bookmarkCollectionList(userId: $userId) {
      ...bookmarkCollectionListParts
    }
  }

  ${bookmarkCollectionListParts}
`;

export default function MySafariCollectionsPage() {
  const { authority } = useAuth();
  invariant(authority, 'Must be authenticated');

  const { data, loading } = useQuery<
    MySafariCollectionsPageQuery,
    MySafariCollectionsPageQueryVariables
  >(MY_SAFARI_COLLECTIONS_PAGE, {
    variables: { userId: authority.user.id },
  });

  const refetchQueries: [
    PureQueryOptions<MySafariCollectionsPageQueryVariables>,
  ] = [
    {
      query: MY_SAFARI_COLLECTIONS_PAGE,
      variables: { userId: authority.user.id },
    },
  ];

  if (loading) return <SpinnerPlaceholder />;
  if (!data?.bookmarkCollectionList) return <NotFound />;

  return (
    <BookmarkCollectionList
      collections={data.bookmarkCollectionList}
      isCompany={false}
      refetchQueries={refetchQueries}
    />
  );
}
