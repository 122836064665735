import type { PureQueryOptions } from '@apollo/client';
import type { MouseEvent } from 'react';
import { Button } from 'react-daisyui';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { Tooltip } from '~/components/common/Tooltip';
import { DeleteOutcropLink } from '~/components/upload/study/DeleteOutcropLink';

type Props = {
  outcropId: number;
  studyId: number;
  numOutcrops: number;
  refetchQueries: PureQueryOptions[];
};

export function RemoveStudyLink({
  outcropId,
  studyId,
  numOutcrops,
  refetchQueries,
}: Props) {
  function handleDisabledClick(event: MouseEvent) {
    event.preventDefault();
  }

  const handleDeleteClick = (handleDelete: Function) => (event: MouseEvent) => {
    event.preventDefault();
    handleDelete();
  };

  if (numOutcrops === 1) {
    return (
      <Tooltip
        message="Link cannot be removed because study is only linked to one outcrop."
        className="w-full text-left"
      >
        <DropdownMenu.Item>
          <a
            href="#remove-link"
            className="cursor-not-allowed block w-full"
            onClick={handleDisabledClick}
          >
            Remove Link
          </a>
        </DropdownMenu.Item>
      </Tooltip>
    );
  }

  return (
    <DropdownMenu.Item>
      <DeleteOutcropLink
        outcropId={outcropId}
        studyId={studyId}
        refetchQueries={refetchQueries}
      >
        {handleDelete => (
          <Button
            type="button"
            onClick={handleDeleteClick(handleDelete)}
            fullWidth
          >
            Remove Link
          </Button>
        )}
      </DeleteOutcropLink>
    </DropdownMenu.Item>
  );
}
