import type { PureQueryOptions } from '@apollo/client';
import {
  faBook,
  faBookmark,
  faPencil,
  faTrash,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import { useState } from 'react';
import { Badge, Button } from 'react-daisyui';
import { Heading } from '~/components/common/Heading';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { Panel } from '~/components/common/Panel';
import { Tooltip } from '~/components/common/Tooltip';
import type { Bookmark } from '~/utils/modules/bookmark';
import { targetName } from '~/utils/modules/bookmark';
import type { Props as BookmarkCollectionManagerProps } from './BookmarkCollectionManager';
import { BookmarkCollectionManager } from './BookmarkCollectionManager';
import { DeleteBookmark } from './DeleteBookmark';
import { UpdateBookmark } from './UpdateBookmark';

type Props = {
  bookmark: Bookmark;
  collections: BookmarkCollectionManagerProps['collections'];
  /** refetch queries fired on delete */
  refetchQueries: PureQueryOptions[];
};

export function BookmarkRenderer({
  bookmark,
  collections,
  refetchQueries,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [isCollectionMgrVisible, setIsCollectionMgrVisible] = useState(false);

  const toggleEditing = () => setIsEditing(prev => !prev);

  const toggleCollectionMgrVisibility = () =>
    setIsCollectionMgrVisible(prev => !prev);

  const isCompany = bookmark.companyId !== null;

  return (
    <Panel>
      <Panel.Body>
        <div className="float-right">
          {!isEditing && (
            <>
              <Tooltip message="Edit note">
                <Button
                  type="button"
                  color="ghost"
                  size="xs"
                  onClick={toggleEditing}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </Button>
              </Tooltip>

              <DeleteBookmark
                bookmark={bookmark}
                refetchQueries={refetchQueries}
              >
                {handleDelete => (
                  <Tooltip message="Delete this bookmark">
                    <Button
                      type="button"
                      color="ghost"
                      size="xs"
                      onClick={handleDelete}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Tooltip>
                )}
              </DeleteBookmark>
            </>
          )}

          {isEditing && (
            <Button
              type="button"
              color="ghost"
              size="xs"
              onClick={toggleEditing}
              className="gap-1"
            >
              <FontAwesomeIcon icon={faX} /> Cancel
            </Button>
          )}
        </div>

        <Heading
          level={4}
          className={cn(
            'space-x-2',
            isCompany ? 'text-wine-500' : 'text-sky-500',
          )}
        >
          <FontAwesomeIcon icon={faBookmark} />
          <span>{targetName(bookmark)}</span>
        </Heading>

        {bookmark.company && (
          <div style={{ fontSize: '10pt', color: '#777777' }}>
            by {bookmark.user.name} ({bookmark.company.name})
          </div>
        )}

        <div className="clear-both" />

        {(bookmark.note || isEditing) && <div className="mt-2" />}

        {!isEditing && (
          <div className="text-base whitespace-pre-line break-words">
            {bookmark.note}
          </div>
        )}
        {isEditing && (
          <UpdateBookmark bookmark={bookmark} onUpdateSuccess={toggleEditing} />
        )}
      </Panel.Body>

      <Panel.Footer>
        <Button
          type="button"
          color="ghost"
          size="xs"
          onClick={toggleCollectionMgrVisibility}
          className="gap-1"
        >
          <FontAwesomeIcon icon={faBook} style={{ marginRight: '5px' }} />
          {bookmark.collections.length === 0
            ? 'Add to collection'
            : 'Collections'}
          {bookmark.collections.length > 0 && (
            <Badge color="ghost">{bookmark.collections.length}</Badge>
          )}
          <ExpandedIcon expanded={isCollectionMgrVisible} />
        </Button>

        {isCollectionMgrVisible && (
          <div style={{ margin: '10px 0 20px 0' }}>
            <BookmarkCollectionManager
              bookmark={bookmark}
              collections={collections}
              refetchQueries={refetchQueries}
            />
          </div>
        )}
      </Panel.Footer>
    </Panel>
  );
}
