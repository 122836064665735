import { gql, useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button, Select } from 'react-daisyui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import type * as schema from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { ReportFormFields } from '~/components/report/ReportEditor/ReportFormFields';
import { useAuth } from '~/contexts/auth';
import { editReportRoute } from '~/paths';
import type { ReportFormValues } from '~/utils/modules/report';
import {
  initialReportFormValues,
  reportValidationSchema,
  toCreateReportInput,
} from '~/utils/modules/report';

const CREATE_REPORT_COLLECTION_LIST = gql`
  query CreateReportCollectionList($userId: Int, $companyId: Int) {
    bookmarkCollectionList(userId: $userId, companyId: $companyId) {
      id
      name
      bookmarks {
        bookmarkId
      }
    }
  }
`;

const CREATE_REPORT = gql`
  mutation CreateReport($report: CreateReportInput!) {
    createReport(report: $report) {
      id
    }
  }
`;

export function CreateReportPage() {
  const { authority } = useAuth();
  invariant(authority, 'Must be authenticated to create a report.');

  const [searchParams] = useSearchParams();
  const isCompany = searchParams.get('company') === 't';
  const collectionIdParam = searchParams.get('collectionId');
  const initialCollectionId = collectionIdParam
    ? parseInt(collectionIdParam)
    : undefined;

  const navigate = useNavigate();

  const { data } = useQuery<
    schema.CreateReportCollectionListQuery,
    schema.CreateReportCollectionListQueryVariables
  >(CREATE_REPORT_COLLECTION_LIST, {
    variables: {
      userId: isCompany ? null : authority.user.id,
      companyId: isCompany ? authority.user.companyId : null,
    },
  });

  const [createReport, { loading, error }] = useMutation<
    schema.CreateReportMutation,
    schema.CreateReportMutationVariables
  >(CREATE_REPORT);

  async function handleSubmit(values: ReportFormValues) {
    try {
      const report = toCreateReportInput(
        values,
        isCompany,
        initialCollectionId,
      );
      const res = await createReport({ variables: { report } });
      const createdId = res.data?.createReport.id;
      invariant(createdId, 'Error parsing response body');
      navigate(editReportRoute(createdId));
      toast.success('Report created successfully.');
    } catch (err) {
      console.log('Error creating report', err);
      toast.error('There was a problem saving the report, please try again.');
    }
  }

  const initialCollection = data?.bookmarkCollectionList.find(
    c => c.id === initialCollectionId,
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialReportFormValues()}
      validationSchema={reportValidationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>
              Create {isCompany ? 'Company' : 'Personal'} Report
            </Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="space-y-4">
              {initialCollection && (
                <div className="form-control">
                  <label htmlFor="initialCollectionId" className="label">
                    <span className="label-text">Based on collection</span>
                  </label>
                  <Select id="initialCollectionId" disabled>
                    <option value={initialCollection.id}>
                      {initialCollection.name} (
                      {initialCollection.bookmarks.length} bookmarks)
                    </option>
                  </Select>
                </div>
              )}

              <ReportFormFields disabled={false} />
              <FormErrors graphQLError={error} />
            </div>
          </Panel.Body>

          <Panel.Footer>
            <div className="text-right">
              <Button type="submit" color="primary" disabled={loading}>
                Save
              </Button>
            </div>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
