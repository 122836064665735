import React from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Panel } from '~/components/common/Panel';
import UpdateSearchView from '~/components/upload/UpdateSearchView';
import * as routes from '~/paths';

type AdminCardProps = {
  children: React.ReactNode;
  title: string;
};

function AdminCard({ children, title }: AdminCardProps) {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>{title}</Panel.Title>
      </Panel.Heading>
      <Panel.Body className="space-y-4 p-4 text-base">{children}</Panel.Body>
    </Panel>
  );
}

type LinkItemProps = LinkProps & {
  label: React.ReactNode;
  children?: React.ReactNode;
};

function LinkItem({ to, label, children, ...linkProps }: LinkItemProps) {
  return (
    <div>
      <Link to={to} className="link font-semibold" {...linkProps}>
        {label}
      </Link>
      {children && <div>{children}</div>}
    </div>
  );
}

export default function AdminUploadRoute() {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="space-y-6">
          <AdminCard title="Wiki Pages">
            <LinkItem to={routes.uploadGeologyRoute()} label="Geology">
              Manage geology wiki pages.
            </LinkItem>

            <LinkItem
              to={routes.uploadLithostratRoute()}
              label="Lithostratigraphy"
            >
              Upload lithostratigraphic data, including groups, formations, and
              members.
            </LinkItem>

            <LinkItem
              to={routes.uploadOtherWikiRoute()}
              label="Other Safari articles"
            >
              Manage other safari articles.
            </LinkItem>

            <hr />

            <LinkItem
              to={routes.uploadLithostratTypesRoute()}
              label="Lithostrat types"
            >
              Manage lithostrat types and their diagrams that are linked to
              groups, formations, and members.
            </LinkItem>

            <LinkItem
              to={routes.uploadDepositionalOverviewRoute()}
              label="Depositional article overview"
            >
              Overview of all depositional types and whether they have a linked
              Geology Wiki article.
            </LinkItem>
          </AdminCard>

          <AdminCard title="Comments">
            <LinkItem to={routes.reviewCommentsRoute()} label="Comment tools">
              Review comments across system
            </LinkItem>
          </AdminCard>

          <AdminCard title="Files">
            <LinkItem to={routes.fileManagerRoute()} label="File manager">
              Upload files that can be embedded within content throughout Safari
            </LinkItem>
          </AdminCard>

          <AdminCard title="Utilities">
            <LinkItem
              to={routes.kpsMissingGeologyTypeRoute()}
              label="Key Parameters missing geology type"
            >
              Overview of key parameters that do not have a geology type.
            </LinkItem>
            <LinkItem
              to={routes.invalidKeyParametersRoute()}
              label="Invalid Key Parameters"
            >
              Overview of all key parameters that are no longer valid according
              to the current depositional hierarchy.
            </LinkItem>
          </AdminCard>
        </div>

        <div className="space-y-6">
          <AdminCard title="Model">
            <LinkItem to={routes.uploadOutcropsRoute()} label="Model data">
              Upload Safari model data, including projects, regions, outcrops,
              and the outcrop depositional hierarchy.
            </LinkItem>

            <LinkItem to={routes.uploadPaleoMapRoute()} label="Paleogeography">
              Upload palaeomap images and manage their links to outcrops.
            </LinkItem>

            <LinkItem to={routes.uploadFieldPicsRoute()} label="Field Pictures">
              Bulk upload field pictures to any outcrop.
            </LinkItem>

            <LinkItem
              to={routes.uploadVomRoute()}
              label="Virtual Outcrop Model"
            >
              Manage virtual outcrops.
            </LinkItem>

            <hr />

            <div>
              <UpdateSearchView>
                {handleUpdate => (
                  <button
                    type="button"
                    className="btn-link font-semibold text-base"
                    onClick={handleUpdate}
                  >
                    Update search
                  </button>
                )}
              </UpdateSearchView>

              <p>
                Changes to model data do not automatically update the search
                results. Use this link to rebuild the search on demand.
              </p>
            </div>
          </AdminCard>

          <AdminCard title="Export">
            <LinkItem
              to={routes.exportOutcropsRoute()}
              target="_blank"
              label="Export Outcrops"
            >
              Master overview of all outcrops and an option to export to a file
            </LinkItem>

            <LinkItem
              to={routes.exportStudiesRoute()}
              target="_blank"
              label="Export Studies"
            >
              Master overview of all studies and an option to export to a file
            </LinkItem>

            <LinkItem
              to={routes.exportVomsRoute()}
              label="Export Virtual Outcrop Models"
            >
              Master overview of all VOMs and an option to export to a file
            </LinkItem>

            <LinkItem
              to={routes.exportGeoreferencesRoute()}
              label="Export Georeferences"
            >
              Export batches of georeferences to KML files
            </LinkItem>
          </AdminCard>
        </div>
      </div>
    </>
  );
}
