import type { CompanyPartsFragment } from '~/apollo/generated/schema';
import { yup } from '~/utils/validation';

export type CompanyFormValues = {
  name: string;
  mails: string[];
  safariLevel: number;
  allowRegister: boolean;
};

export const initialCompanyValues = (
  company?: CompanyPartsFragment,
): CompanyFormValues => ({
  name: company?.name ?? '',
  mails: company?.mails ?? [],
  safariLevel: company?.safariLevel ?? 3,
  allowRegister: company?.allowRegister ?? true,
});

export const companyValidationSchema = yup.object({
  allowRegister: yup.boolean().required(),
  mails: yup
    .array(
      yup
        .string()
        .required()
        .test(
          'is-domain',
          'must be in format `@domain.com`',
          value => !!value?.match(/@.+\.[a-z]+$/),
        ),
    )
    .min(1)
    .required(),
  name: yup.string().required(),
  safariLevel: yup.number().integer().required(),
});
