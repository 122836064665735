import {
  faExternalLink,
  faFlag,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Join } from 'react-daisyui';
import { Link } from 'react-router-dom';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { Tooltip } from '~/components/common/Tooltip';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import * as routes from '~/paths';
import type { SortableStudy } from '~/routes/upload/model/__uploadModelsPage/studies';

function approvalIcon(state: SortableStudy['approvalState']): JSX.Element {
  if (state === 'approved') return <EnabledIndicator value={true} />;
  if (state === 'ready for approval')
    return <FontAwesomeIcon icon={faFlag} className="text-warning" />;
  return <EnabledIndicator value={false} />;
}

type Props = {
  study: SortableStudy;
};

export function StudyListItem({ study }: Props) {
  return (
    <tr>
      <td className="text-center">
        <Tooltip message={study.approvalState}>
          <span>{approvalIcon(study.approvalState)}</span>
        </Tooltip>
      </td>
      <td className="text-center">{study.id}</td>
      <td>{study.type}</td>
      <td>
        <Link to={routes.uploadStudyUpdateRoute(study.id)} className="link">
          {study.name}
        </Link>
      </td>
      <td>{study.dataHistory?.collectedBy}</td>
      <td>{study.qualityParameters?.publicationType?.join(', ')}</td>
      <td>
        {study.measurementCount} / {study.aeCount}
      </td>
      <td>
        {!study.hasOutcrops && (
          <div className="text-center">
            <EnabledIndicator value={false} />
          </div>
        )}
        {study.outcrops.map(oc => (
          <div key={oc.id}>
            <Link to={routes.uploadOutcropUpdateRoute(oc.id)} className="link">
              {oc.name}
            </Link>
          </div>
        ))}
      </td>
      <td>
        <Link
          to={routes.uploadProjectUpdateRoute(study.project.id)}
          className="link"
        >
          {study.project.name}
        </Link>
      </td>
      <td className=" text-center">
        <EnabledIndicator
          value={study.qcCompleted}
          className={study.redundant ? 'text-muted' : undefined}
        />
      </td>
      <td className="text-right">
        <div className="min-w-32">
          <Join>
            <Link
              to={routes.uploadStudyUpdateRoute(study.id)}
              className="btn btn-ghost btn-sm gap-1 join-item"
            >
              <FontAwesomeIcon icon={faPencil} /> Edit
            </Link>

            <DropdownMenu color="ghost" size="sm" join>
              <DropdownMenu.Items>
                <DropdownMenu.Item>
                  <Link
                    to={routes.studyRoute(study.id)}
                    target="_blank"
                    className="gap-1"
                  >
                    View <FontAwesomeIcon icon={faExternalLink} />
                  </Link>
                </DropdownMenu.Item>
              </DropdownMenu.Items>
            </DropdownMenu>
          </Join>
        </div>
      </td>
    </tr>
  );
}
