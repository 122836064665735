import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import {
  faBook,
  faBookmark,
  faScroll,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Menu } from 'react-daisyui';
import { Outlet, useOutletContext } from 'react-router-dom';
import * as fragments from '~/apollo/fragments';
import type {
  MySafariPageNavigationQuery,
  MySafariPageNavigationQueryVariables,
} from '~/apollo/generated/schema';
import { Heading } from '~/components/common/Heading';
import { NavLink } from '~/components/common/NavLink';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { PageIntro } from '~/components/layout/PageIntro';
import { useAuth } from '~/contexts/auth';
import {
  bookmarksRoute,
  collectionsRoute,
  companyBookmarksRoute,
  companyCollectionsRoute,
  companyReportsRoute,
  reportsRoute,
} from '~/paths';

const MY_SAFARI_PAGE = gql`
  query MySafariPageNavigation($userId: Int!, $companyId: Int!) {
    personalCollections: bookmarkCollectionList(userId: $userId) {
      ...bookmarkCollectionParts
    }
    companyCollections: bookmarkCollectionList(companyId: $companyId) {
      ...bookmarkCollectionParts
    }
  }

  ${fragments.bookmarkCollectionParts}
`;

type OutletContext = {
  personalCollections: MySafariPageNavigationQuery['personalCollections'];
  companyCollections: MySafariPageNavigationQuery['companyCollections'];
  allCollections: MySafariPageNavigationQuery['personalCollections']; // these are the same type
  refetchQueries: PureQueryOptions[];
};

export default function MySafariRoute() {
  const { authority } = useAuth();
  if (!authority) throw new Error('Must be authenticated');

  useBreadcrumb('routes/my-safari', 'My Safari');

  const queryVars = {
    userId: authority.user.id,
    companyId: authority.user.companyId,
  };
  const { data, loading } = useQuery<
    MySafariPageNavigationQuery,
    MySafariPageNavigationQueryVariables
  >(MY_SAFARI_PAGE, { variables: queryVars });

  const refetchQueries: [
    PureQueryOptions<MySafariPageNavigationQueryVariables>,
  ] = [{ query: MY_SAFARI_PAGE, variables: queryVars }];

  const personalCollections = data?.personalCollections ?? [];
  const companyCollections = data?.companyCollections ?? [];

  const sortCollections = R.sortBy(R.prop('name'));
  const sortedPersonalCollections = sortCollections(personalCollections);
  const sortedCompanyCollections = sortCollections(companyCollections);
  const allCollections = [
    ...sortedPersonalCollections,
    ...sortedCompanyCollections,
  ];

  if (loading) return <SpinnerPlaceholder />;

  const outletContext: OutletContext = {
    personalCollections: sortedPersonalCollections,
    companyCollections: sortedCompanyCollections,
    allCollections,
    refetchQueries,
  };

  return (
    <div className="space-y-4">
      <PageIntro pageName="my-safari" />

      <div className="grid lg:grid-cols-4 gap-6">
        <div className="space-y-4">
          <div>
            <Heading level={5}>
              <FontAwesomeIcon icon={faBookmark} className="mr-2" />
              Bookmarks
            </Heading>
            <Menu>
              <Menu.Item>
                <NavLink to={bookmarksRoute()} end>
                  My Bookmarks
                </NavLink>
              </Menu.Item>

              <Menu.Item>
                <NavLink to={companyBookmarksRoute()}>
                  Company Bookmarks
                </NavLink>
              </Menu.Item>
            </Menu>
          </div>

          <div>
            <Heading level={5}>
              <FontAwesomeIcon icon={faBook} className="mr-2" />
              Collections
            </Heading>
            <Menu>
              <Menu.Item>
                <NavLink to={collectionsRoute()} end>
                  My Collections
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink to={companyCollectionsRoute()} end>
                  Company Collections
                </NavLink>
              </Menu.Item>
            </Menu>
          </div>

          <div>
            <Heading level={5}>
              <FontAwesomeIcon icon={faScroll} className="mr-2" />
              Reports
            </Heading>

            <Menu>
              <Menu.Item>
                <NavLink to={reportsRoute()} end>
                  My Reports
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink to={companyReportsRoute()}>Company Reports</NavLink>
              </Menu.Item>
            </Menu>
          </div>
        </div>

        <div className="lg:col-span-3">
          <Outlet context={outletContext} />
        </div>
      </div>
    </div>
  );
}

export function useMySafariOutletContext() {
  return useOutletContext<OutletContext>();
}
