import { Field, useFormikContext } from 'formik';
import { useMemo } from 'react';
import { FormikField } from '~/components/common/FormikField';
import type { CrossPlotDataPoint } from '~/utils/chart';
import {
  crossPlotRegressionFormula,
  crossPlotRSquared,
  zeroInterceptRegressionFormula,
} from '~/utils/chart';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';

const SCALE = 4;

function regressionFormulaText(
  slope: number,
  intercept: number,
  isLogScaleX: boolean,
  isLogScaleY: boolean,
) {
  const x = isLogScaleX ? ' · ln(x)' : 'X';
  const y = isLogScaleY ? 'ln(ŷ)' : 'ŷ';
  const m = slope.toFixed(SCALE);
  const sign = intercept >= 0 ? '+' : '-';
  const b =
    intercept === 0 ? '' : ` ${sign} ${Math.abs(intercept).toFixed(SCALE)}`;

  return `${y} = ${m}${x}${b}`;
}

type Props = {
  data: CrossPlotDataPoint[];
};

export function RegressionFormFields({ data }: Props) {
  const { values } = useFormikContext<DataSearchFormValues>();

  const regressionVars = useMemo(() => {
    return crossPlotRegressionFormula(
      data,
      values.crossPlot.logScaleX,
      values.crossPlot.logScaleY,
    );
  }, [data, values.crossPlot.logScaleX, values.crossPlot.logScaleY]);
  const { m, b } = regressionVars;

  const ziRegressionVars = useMemo(
    () => zeroInterceptRegressionFormula(data),
    [data],
  );
  const { m: ziSlope } = ziRegressionVars;

  // R² values are calculated based on non-log data
  const r2RegressionVars = crossPlotRegressionFormula(data, false, false);
  const ziR2RegressionVars = zeroInterceptRegressionFormula(data);
  const rSquaredValue = crossPlotRSquared(data, r2RegressionVars).toFixed(
    SCALE,
  );
  const ziRSquaredValue = crossPlotRSquared(data, ziR2RegressionVars).toFixed(
    SCALE,
  );

  return (
    <div className="grid lg:grid-cols-2 gap-6">
      <div>
        <Field
          name="crossPlot.showRegressionLine"
          label="Linear regression"
          component={FormikField}
          type="checkbox"
          size="sm"
        />
        {values.crossPlot.showRegressionLine && (
          <div className="pl-3">
            <div className="border-l-4 pl-4 border-l-slate-300 font-mono">
              <b>
                {regressionFormulaText(
                  m,
                  b,
                  values.crossPlot.logScaleX,
                  values.crossPlot.logScaleY,
                )}
              </b>
              <br />R<sup>2</sup> ≈ {rSquaredValue}
            </div>
          </div>
        )}
      </div>

      <div>
        <Field
          name="crossPlot.showZIRegressionLine"
          label="Linear regression through origin"
          component={FormikField}
          type="checkbox"
          size="sm"
          disabled={values.crossPlot.logScaleX || values.crossPlot.logScaleY}
        />
        {values.crossPlot.showZIRegressionLine && (
          <div className="pl-3">
            <div className="border-l-4 pl-4 border-l-slate-300 font-mono">
              <b>{regressionFormulaText(ziSlope, 0, false, false)}</b>
              <br />R<sup>2</sup> ≈ {ziRSquaredValue}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
