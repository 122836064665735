import { z } from 'zod';

export const envVarsSchema = z.object({
  VITE_CLIENT_URL: z.string().url(),
  VITE_GRAPHQL_URL_V3: z.string().url(),
  VITE_PHOENIX_OPEN_API_URL_V4: z.string().url(),
  VITE_AUTH0_DOMAIN: z.string(),
  VITE_AUTH0_CLIENT_ID: z.string(),
  VITE_AUTH0_CLIENT_AUD: z.string(),
  VITE_ION_ASSET_TOKEN: z.string()
});

export type BrowserEnvVars = z.infer<typeof envVarsSchema>;

export function createBrowserEnvVars(): BrowserEnvVars {
  const vars = {
    VITE_CLIENT_URL: import.meta.env.VITE_CLIENT_URL,
    VITE_GRAPHQL_URL_V3: import.meta.env.VITE_GRAPHQL_URL_V3,
    VITE_PHOENIX_OPEN_API_URL_V4: import.meta.env.VITE_PHOENIX_OPEN_API_URL_V4,
    VITE_AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
    VITE_AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_CLIENT_AUD: import.meta.env.VITE_AUTH0_CLIENT_AUD,
    VITE_ION_ASSET_TOKEN: import.meta.env.VITE_ION_ASSET_TOKEN
  };

  return envVarsSchema.parse(vars);
}

export const envVars = createBrowserEnvVars();
